<template>
  <div class="fullMap d-flex align-items-center justify-content-center h-100">
    <!-- <img src="assets/images/locationMap.svg" alt="Map"> -->
    <div
      id="chartdiv"
      class="worldMAP"
      style="width: 100%; height: 500px"
      v-show="!store.loading"
    ></div>
  </div>
</template>

<script>
import { useMainStore } from "@/store";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import * as am5map from "@amcharts/amcharts5/map";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import am5geodata_worldLow from "@amcharts/amcharts5-geodata/worldLow";
import am5geodata_continentsLow from "@amcharts/amcharts5-geodata/continentsLow";
import * as percent from "@amcharts/amcharts5/percent";
import * as index from "@amcharts/amcharts5/index";
export default {
  setup() {
    const store = useMainStore();
    return { store };
  },
  data() {
    return {};
  },
  watch: {
    "store.selectedCountry"() {
      this.drawChart(this.$route.query.country);
    },
    "$route.query.country"(){
      this.drawChart(this.$route.query.country);
    }
  },
  methods: {
    drawChart(country) {
      let d = am5.registry.rootElements.find(
        (i) => i.dom && i.dom.id === "chartdiv"
      );
      d?.dispose();
      // console.log(am5);
      var root = am5.Root.new("chartdiv");
      root.setThemes([am5themes_Animated.new(root)]);
      var chart = root.container.children.push(
        // am5map.MapChart.new(root, {
        //   panY : "false",
        //   panX : "false",
        //   wheelY: "zoomX",
        //   whhelX: "zoomY",
        //   projection: am5map.geoMercator(),
        // })
        am5map.MapChart.new(root, {
          panX: "translateX",
          panY: "translateY",
          projection: am5map.geoMercator(),
        })
      );

      var polygonSeries = chart.series.push(
        am5map.MapPolygonSeries.new(root, {
          geoJSON: am5geodata_worldLow,
          exclude: ["AQ"],
        })
      );
      polygonSeries.useGeodata = true;
      polygonSeries.mapPolygons.template.setAll({
        tooltipText: "{name}",
        toggleKey: "active",
        interactive: true,
        templateField: "polygonSettings",
        fill: "#B7DCC9",
      });
      polygonSeries.mapPolygons.template.states.create("hover", {
        fill: "#B7DCC9",
      });

      polygonSeries.mapPolygons.template.states.create("active", {
        fill: "#16C784",
      });
      var previousPolygon;
      polygonSeries.mapPolygons.template.on("active", (active, target) => {
        if (this.$route.name == "economy_overview") {
          // this.store.$patch({
          //   selectedCountry: target.dataItem.dataContext.name,
          // });
          // this.store.$patch({ selectedIndicator: "GDP" });
          // this.$router.replace({
          //   path: this.$route.path,
          //   query: { country: target.dataItem.dataContext.name },
          // });
          this.$parent.showCountryData(target.dataItem.dataContext.name);
        } else {
          this.$router.replace({
            path: this.$route.path,
            query: { country: target.dataItem.dataContext.name },
          });
          // this.store.$patch({
          //   selectedCountry: target.dataItem.dataContext.name,
          // });
          //this.$parent.drawCompositionChart()
        }
        if (previousPolygon && previousPolygon != target) {
          previousPolygon.set("active", false);
        }
        if (target.get("active")) {
          polygonSeries.data.setAll([
            {
              id: target.dataItem.dataContext.id,
              polygonSettings: {
                active: true,
              },
            },
          ]);
          if (this.$route.name == "markets") {
            this.$parent.getCountryWiseData(target.dataItem.dataContext.name);
            this.$parent.callCountryEconomyMarketData(
              target.dataItem.dataContext.name
            );
          } else if (this.$route.name == "latest_news") {
            this.$parent.getLatestNews(target.dataItem.dataContext.name);
          } else {
            if (this.$route.query.country != target.dataItem.dataContext.name) {
              this.callCountryEconomyData(target.dataItem.dataContext.name);
            }
          }
        } else {
          chart.goHome();
        }
        previousPolygon = target;
      });
      if (country && this.$route.name != "economy_overview") {
        let countryObject = am5geodata_worldLow.features.find(
          (i) => i.properties.name === country
        );
        polygonSeries.data.setAll([
          {
            id: countryObject.id,
            polygonSettings: {
              active: true,
            },
          },
        ]);
      }
      chart.appear(1000, 100);
    },
    callCountryEconomyData(country) {
      let formData = {};
      if (this.$route.name == "gdp") {
        formData = {
          country: [country],
          group: "GDP",
        };
      } else if (this.$route.name == "money_and_price") {
        formData = {
          country: country,
          group: ["Money", "Prices"],
        };
      } else if (this.$route.name == "markets") {
        formData = {
          country: [country],
          group: "Markets",
          indicator: ["Stock Market", "Currency"],
          year: 0,
          filters: { type: "previous_data" },
        };
      } else if (this.$route.name == "business_consumer") {
        formData = {
          country: country,
          group: ["Business", "Consumer"],
        };
      } else if (this.$route.name == "revenue_and_expenditure") {
        formData = {
          country: country,
          group: ["Government", "Taxes"],
        };
      } else if (this.$route.name == "labour_and_health") {
        formData = {
          country: country,
          group: ["Labour", "Health"],
        };
      } else if (this.$route.name == "forex_and_trade") {
        formData = {
          country: country,
          group: ["Trade"],
        };
      } else if (this.$route.name == "imports_and_exports") {
        formData = {
          country: country,
          group: ["Trade"],
        };
      }
      this.store
        .getCountryEconomyData(formData, true)
        .then((response) => {})
        .catch((err) => console.log(err.message));
    },
  },
  mounted() {},
  created() {
    am5.ready(() => {
      setTimeout(() => {
        this.drawChart(this.$route.query.country);
      }, 500);
    });
  },
};
</script>
<!-- <style>
.worldMAP > div {
  display: none;
}
.worldMAP > div:last-child {
  display: block !important;
}
</style> -->
