<template>
  <h2 class="mb-3">Economy Overview</h2>
  <div class="form-group inputIcon tagInput position-relative mb-3">
    <div class="bootstrap-tagsinput" v-if="$route.path != '/economy-overview'">
      <span class="badge badge-info d-flex align-items-center"
        v-if="$route.query.country"
        >{{ $route.query.country }}
        <!-- <vue-feather class="ms-1" size="12px" type="x" @click="search = ''"></vue-feather -->
      </span>
      <input type="text" class="form-control-tag" v-model="search" />
      <!-- <a class="searchButton" href="javascript:void(0)"
        ><vue-feather type="search"></vue-feather
      ></a> -->
      <ul
        class="dropdown_menu_animated searchResult show justify-content-between d-flex flex-wrap p-3 home-banner-search echoSearch" 
        v-if="search">
        <li v-for="country of searchCountry">
          <a href="javascript:void(0)" class="py-2 border-0 text-center" @click="chnageCountry(country)">
            {{country}}
          </a>
        </li>
    </ul>
    </div>
    <select
      class="form-select"
      name="continental"
      v-model="$parent.continent"
      @change="() => $parent.callCountryEconomyData()"
      v-else
    >
      <option
        v-for="(value, key) in static_vars.economicOverviewContinents"
        :key="value"
      >
        {{ value }}
      </option>
    </select>
  </div>
</template>
<script>
import { useMainStore } from "@/store";
export default {
  setup() {
    const store = useMainStore();
    return { store };
  },
  data() {
    return {
      search : ''
    };
  },
  computed:{
    searchCountry(){
      if(this.allCountries.length){
        if(this.search){
          return this.allCountries.filter(item => item.toLowerCase().includes(this.search.toLowerCase()))
        }else{
          return this.allCountries
        }
      }
    },
    allCountries() {
        let arr = [];
        for (let key in this.static_vars.economicCalendarCountries) {
            this.static_vars.economicCalendarCountries[key].map((i) => {
                arr.push(i);
            });
        }
        return [...new Set(arr)].sort((a, b) => a.localeCompare(b));
    },
  },
  methods:{
    chnageCountry(country){
      this.$router.replace({
        path: this.$route.path,
        query: { country: country },
        });
      this.search = ''
      let formData = {};
      if (this.$route.name == "gdp") {
        formData = {
          country: [country],
          group: "GDP",
        };
      } else if (this.$route.name == "money_and_price") {
        formData = {
          country: country,
          group: ["Money", "Prices"],
        };
      } else if (this.$route.name == "markets") {
        formData = {
          country: [country],
          group: "Markets",
          indicator: ["Stock Market", "Currency"],
          year: 0,
          filters: { type: "previous_data" },
        };
      } else if (this.$route.name == "business_consumer") {
        formData = {
          country: country,
          group: ["Business", "Consumer"],
        };
      } else if (this.$route.name == "revenue_and_expenditure") {
        formData = {
          country: country,
          group: ["Government", "Taxes"],
        };
      } else if (this.$route.name == "labour_and_health") {
        formData = {
          country: country,
          group: ["Labour", "Health"],
        };
      } else if (this.$route.name == "forex_and_trade") {
        formData = {
          country: country,
          group: ["Trade"],
        };
      } else if (this.$route.name == "imports_and_exports") {
        formData = {
          country: country,
          group: ["Trade"],
        };
      }
      if (this.$route.name == "latest_news") {
        this.$parent.getLatestNews(country);
      }else{
        this.store
          .getCountryEconomyData(formData, true)
          .then((response) => {})
          .catch((err) => console.log(err.message));
      }
    }
  }
};
</script>
