<template>
    <Vue3Marquee class="animation d-flex" :duration="3000" :pauseOnClick="true">
        <div class="marquee-row" v-for="(spreads, key, index) in getdata" :key="index">
            <div class="marquee-column">
                <div class="currencyFlag">
                    <a href="javascript:void(0);" v-if="spreads.other">
                      <img :src=" 'assets/images/crypto/' + spreads.other.isinid.slice(0, 3).toLowerCase() + '.webp' " alt="" />
                    </a>
                    <a href="javascript:void(0);" v-if="spreads.other">
                      <img :src=" 'assets/images/crypto/' + spreads.other.isinid.slice(3, 7).toLowerCase() + '.webp' " alt="" />
                    </a>
                </div>
                <div class="d-flex align-items-center inboxMob">
                    <span class="f-13" v-if="spreads.other">
                      {{spreads.other.isinid}}
                    </span>
                    <span class="f-12">
                      {{COMMON.formatPrice(spreads.B, true)}}
                    </span>
                </div>
                <span class="f-10 d-flex align-items-center value" :class="[{red: parseFloat(spreads.CB) < 0, green: parseFloat(spreads.CB) > 0,},]">
                    {{COMMON.formatPrice(spreads.CB)}}% 
                    <i class="ml-2 fa" aria-hidden="true" :class="[{'fa-caret-down': parseFloat(spreads.CB) < 0, 'fa-caret-up': parseFloat(spreads.CB) > 0,},]"></i>
                </span>
            </div>
        </div>
    </Vue3Marquee>
</template>
<script>
import { Vue3Marquee } from 'vue3-marquee'
import { Const } from "@/plugins/vue-provider-cache/const.mod";
export default {
  data() {
    return {};
  },
    computed: {
        getdata(){
            try{
                if(Object.keys(this.get_currencies).length){
                    return Object.values(this.get_currencies).filter(i => i.other.type != 'stocks')
                }
            }catch (ex) {
                return {};
            }
        },
        get_currencies() {
          try {
            this.$store.getters[Const.GET_HEARTBEATS]["priceFeed"];
            return this.$store.getters[Const.GET_PROVIDERS][
              "priceFeed"
            ].iCache.asKeysValueArrayAll();
          } catch (ex) {
            return {};
          }
        },
    },
     components: {
        Vue3Marquee,
    },
  methods: {},
};
</script>
